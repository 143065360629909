<template>
  <div>
    <nav-bar
      v-if="showBackBtn"
      @onBack="handleBack"
    />
    <div
      v-if="isClose"
      class="poiTask"
    >
      <div
        v-if="isShowCity"
        class="poiTaskUpload"
      >
        <div class="poiTaskInformation">
          <span>信息提报汇总:</span>
          <van-button
            plain
            :type="replayType"
            size="small"
            :loading="replayLoading"
            class="poiReplayBtn"
            @click="handleReplay"
          >
            <van-icon
              name="replay"
              :style="iconStyle"
            />
          </van-button>
        </div>
        <van-grid>
          <van-grid-item
            v-for="value in informationReportConfig"
            :key="value.value"
            :text="value.name"
          />
        </van-grid>
        <van-grid v-if="!!informationReportData.length">
          <van-grid-item
            v-for="(value, index) in informationReportData"
            :key="`${value}-${index}`"
            :text="value"
          />
        </van-grid>
        <van-grid
          v-else
          :column-num="1"
        >
          <van-grid-item text="暂无数据" />
        </van-grid>
      </div>
      <div class="poiTaskUpload">
        <div>
          <search-select
            title="公司名称："
            task-type="poi"
            :actions="company"
            default-value="DD"
            @getParams="handleGetCompany"
          />
          <div
            v-if="isShowCity"
            class="city"
          >
            <search-select
              title="城市："
              task-type="poi"
              :actions="cityData"
              @getParams="handleGetCity"
            />
          </div>
        </div>
        <div class="address-information">
          <span>地址信息：{{ address || '暂无' }}</span>
          <van-button
            v-if="!!address"
            size="mini"
            class="copyBtn"
            @click="handleCopy"
          >复制</van-button>
        </div>

        <div class="poiTaskUploadFile">
          <span class="toolTip">自提点上传建议使用长截图</span>
          <span class="remarks">(请勿上传拍照/视频/超过7天的历史截图)</span>
          <div>
            <div class="lab">
              <span>地址选择：</span>
              <span
                class="lab-demo"
                @click="show = true"
              >载图示例</span>
            </div>
            <image-collection
              ref="addressImg"
              :accept="'image/*'"
              :max-count="1"
            />
          </div>
        </div>
      </div>
      <div class="submit">
        <van-button
          :loading="loading"
          loading-text="提交中..."
          class="poiSubmitBtn"
          :style="bgColor"
          @click="handleSubmit"
        >
          点我提交!
        </van-button>
        <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
      </div>
    </div>
    <van-empty
      v-else
      description
    />

    <van-popup
      v-model="show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="preview-address">
        <van-image
          width="100%"
          fit="contain"
          src="../../../assets/943895058.png"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import imageCollection from '../ImageCollection/Index'
import searchSelect from '../components/search-select'
import NavBar from '../components/nav-bar.vue'

import { Dialog, Toast } from 'vant'
import { PASSPORT_SIGN, RUI_TU_SIGN, ACCEPT_IMAGE, ACCEPT_VIDEO, IMAGE_TYPES, VIDEO_TYPES } from '@/constant'
import {
  taskSubmit,
  passportSubmitTask,
  getCity,
  getUserTaskData,
  getPassportUserTaskData,
  judgeWhetherUploadVideoPoi,
  judgeWhetherUploadVideoPoiRuiTu,
  getTaskDetails,
} from '@/apis'
import { checkUploadImages, getUrlParams, userAccessValid, copyText } from '../shared'
// import { updateUniqueFileList } from '@/tools/file-upload'
import backListMixins from '../mixins/back-list'

export default {
  name: 'SkuTask',
  components: {
    searchSelect,
    NavBar,
    imageCollection,
  },
  mixins: [backListMixins],
  data() {
    return {
      urlParams: {},
      loading: false,
      accept: ACCEPT_IMAGE.join(','),
      company: [{ name: 'DD', value: 81 }],
      cityData: [],
      companyId: 81,
      cityId: null,
      isShowCity: false,
      replayType: 'info',
      replayLoading: false,
      bgColor: { backgroundColor: '#ffc300' },
      iconStyle: { color: '#ffc300' },
      informationReportConfig: [
        { name: '今日贡献团点数', value: 'contributePoiCountToday' },
        { name: '近七日贡献团点数', value: 'contributePoiCountWeek' },
        { name: '累计贡献团点数', value: 'contributeAllPoiCount' },
        { name: '累计上传图片数', value: 'contributeAllPicCount' },
      ],
      informationReportData: [],
      isClose: true,
      taskStartTime: 0,
      taskEndTime: 0,
      address: '',
      show: false,
    }
  },
  created() {
    this.urlParams = getUrlParams(this.$route.query)
    const { from_channel: channel } = this.urlParams
    if (channel === RUI_TU_SIGN) {
      this.accept = [...IMAGE_TYPES, ...ACCEPT_IMAGE].join(',')
    }
    this.checkUploadVideo()
    this.getUserAccessValid()
    this.handlePoiDetails()
  },
  mounted() {
    // condition 1-常驻 2-非常驻
    this.isShowCity = this.urlParams.condition === 1
    if (this.isShowCity) {
      this.getUserTaskData()
    }
    if (this.urlParams.device === 1) {
      this.bgColor.backgroundColor = '#007aff'
      this.iconStyle.color = '#007aff'
      this.replayType = 'info'
    } else {
      this.bgColor.backgroundColor = '#ffc300'
      this.iconStyle.color = '#ffc300'
      this.replayType = 'primary'
    }
  },
  methods: {
    async checkUploadVideo() {
      if (window.eraState.elk) {
        this.accept = ''
        return
      }
      // 接口校验该用户是否可以上传视频，data为true则可以上传视频
      const { from_channel: channel, login_type: loginType } = this.urlParams
      let res
      if (channel === RUI_TU_SIGN && loginType === PASSPORT_SIGN) {
        res = await judgeWhetherUploadVideoPoiRuiTu()
      } else {
        res = await judgeWhetherUploadVideoPoi()
      }
      if (res && res.code === 0 && res.data) {
        if (channel === RUI_TU_SIGN) {
          this.accept = [...IMAGE_TYPES, ...VIDEO_TYPES, ...ACCEPT_IMAGE, ...ACCEPT_VIDEO].join(',')
        } else {
          this.accept = [...ACCEPT_IMAGE, ...ACCEPT_VIDEO].join(',')
        }
      }
    },
    async getUserAccessValid() {
      const isValid = await userAccessValid(this.urlParams)
      this.isClose = isValid
      if (isValid) {
        await this.handleGetCitySourceData()
      }
    },

    async handlePoiDetails() {
      try {
        let res = await getTaskDetails({ userTaskId: this.urlParams.userTaskId, status: this.urlParams.status ? +this.urlParams.status : undefined })
        if (res) {
          if (res.code !== 0) return
          const data = res.data || {}
          this.address = data.address
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getUserTaskData() {
      try {
        const { from_channel: channel, login_type: loginType } = this.urlParams
        let res
        // passport 锐兔的passport用户调用passport接口
        if (channel === RUI_TU_SIGN && loginType === PASSPORT_SIGN) {
          res = await getPassportUserTaskData({})
        } else {
          res = await getUserTaskData({})
        }
        if (res) {
          if (res.code !== 0) return Toast.fail(res.msg || '用户任务贡献接口错误')
          let data = res.data || []
          this.informationReportData = this.informationReportConfig.map(item => String(data[item.value]))
        }
      } catch (err) {
        Toast.fail(err.msg || '获取任务信息失败')
      } finally {
        this.replayLoading = false
      }
    },
    async handleGetCitySourceData() {
      try {
        const res = await getCity()
        if (res) {
          this.cityData = (res.data || []).map(item => ({ name: item.cityName, value: item.cityId }))
        }
      } catch (err) {
        Toast.fail(err.msg || '获取城市信息失败')
      }
    },
    // 刷新用户任务贡献
    handleReplay() {
      this.replayLoading = true
      this.getUserTaskData()
    },
    handleGetCategory(value) {
      this.skuCategory = value
    },
    handleGetCompany(value) {
      this.companyId = value
    },
    handleGetCity(value) {
      this.cityId = value
    },
    // 提交任务接口
    async fetchTaskNew(submitInfo) {
      const {
        jobId,
        cityId,
        userTaskId,
        condition,
        device,
        from_channel: channel,
        login_type: loginType,
      } = this.urlParams
      let params = {
        jobId,
        companyId: this.companyId,
        cityId,
        userTaskId,
        dataType: 2, // 1sku 采集 2 poi采集,
        ...submitInfo,
      }
      if (condition === 1 && device === 1) {
        params = {
          ...params,
          cityId: this.cityId,
        }
      }
      this.loading = true
      try {
        let res
        // passport 锐兔的passport用户调用passport接口
        if (channel === RUI_TU_SIGN && loginType === PASSPORT_SIGN) {
          res = await passportSubmitTask(params, channel, loginType)
        } else {
          res = await taskSubmit(params, channel, loginType)
        }
        if (res) {
          this.taskEndTime = Date.now()
          this.loading = false
          let msg = '恭喜您提交任务成功！'
          let confirmButtonText = '多喝热水！'
          if (res.code !== 0) {
            msg = res.msg || '很遗憾任务提交失败！'
            confirmButtonText = '我再试试！'
          }
          if (res.code === 0) {
            // updateUniqueFileList(imgs)
          }
          let options = {
            message: msg,
            confirmButtonText,
          }
          try {
            await Dialog.alert(options)
            if (res.code === 0) {
              window.close()
              this.isClose = false
            }
          } catch (err) {
            Toast.fail(err.message || 'Dialog提交错误')
          }
        }
      } catch (err) {
        Toast.fail(err.message || '获取任务详情接口请求错误')
      } finally {
        this.loading = false
      }
    },
    onFileChange() {
      this.taskStartTime = Date.now()
    },
    async handleSubmit() {
      if (this.loading) return
      const imgs = this.$refs.poiImg.getImagesList()
      const addressImg = this.$refs.addressImg.getImagesList()
      const submitInfo = await checkUploadImages(imgs)
      const addressImgInfo = await checkUploadImages(addressImg)
      if (submitInfo && addressImgInfo) {
        submitInfo.addressUrl = addressImgInfo.picUrlList[0]
        this.fetchTaskNew(submitInfo, imgs)
      }
    },
    handleCopy() {
      copyText(this.address)
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
  },
}
</script>

<style lang="less" scoped>
.poiTask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .poiTaskUpload {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    .poiTaskInformation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .poiReplayBtn {
        width: 35px;
        background-color: #e2e2e3;
        border: none;
      }
      .list {
        display: flex;
      }
    }
    .city {
      margin-top: 10px;
      margin-left: 28px;
    }
    .poiTaskUploadFile {
      margin-top: 20px;
      .toolTip {
        display: inline-block;
        margin-bottom: 5px;
      }
      .remarks {
        display: block;
        margin-bottom: 20px;
        color: red;
      }

      .lab {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        margin-bottom: 10px;

        .lab-demo {
          color: #999;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .address-information {
      display: flex;
      align-items: center;
      padding: 10px 0;
      span {
        display: inline-block;
        max-width: 80%;
      }
      .copyBtn {
        width: 20%;
        margin-left: 10px;
        color: #fff;
        background-color: #007aff;
      }
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .poiSubmitBtn {
      width: 100%;
      color: #fff;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
.preview-address {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 40px 0 10px;
}
</style>
