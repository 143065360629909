<template>
  <div class="searchSelect">
    <span>{{ title }}</span>
    <span class="searchSelectContent">
      <van-cell
        is-link
        :value="value || defaultValue"
        @click="show = true"
      />
      <van-action-sheet
        v-model="show"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @cancel="onCancel"
        @select="handleSelect"
      ></van-action-sheet>
    </span>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'SearchSelect',
  props: {
    title: {
      type: String,
      default: '',
    },
    taskType: {
      type: String,
      default: '',
    },
    actions: {
      type: Array,
      default: () => [],
    },
    getParams: {
      type: Function,
      default: () => {},
    },
    defaultValue: {
      type: [String, Number],
      default: '',
    },
    search: {
      type: Boolean,
      default: false,
    },
    addCategory: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      value: '',
      searchValue: '',
    }
  },
  methods: {
    onCancel() {
      Toast('取消')
    },
    handleSelect(action) {
      this.value = (action || {}).name
      this.$emit('getParams', (action || {}).value)
    },
  },
}
</script>

<style rel="stylesheet/less" lang="less" scoped>
.searchSelect {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .searchSelectContent {
    display: inline-block;
    min-width: 200px;
  }
}
/deep/ .van-cell {
  border-radius: 10px;
}
</style>
